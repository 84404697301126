<template>
	<div>
		<table
			class="table"
		>
			<colgroup>

			</colgroup>
			<thead>
				<tr>
					<th>No</th>
					<th>입금자</th>
					<th>입금은행</th>
					<th>예금주</th>
					<th>구분</th>
					<th>입금액</th>

					<th>수수료</th>
					<th>입금일</th>
					<th>승인일</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item_confirm, index) in items_list"
					:key="'item_' + index"
				>
					<td>{{ item_confirm.no }}</td>
					<td>{{ item_confirm.bank_holder }}</td>
					<td>{{ item_confirm.bank_name }}</td>
					<td>{{ item_confirm.bank_depositor }}</td>
					<td>{{ item_confirm.history_type_name }}</td>
					<td :class="'color-' + item_confirm.history_type_color">{{ item_confirm.amount | makeComma }}원</td>
					<td :class="'color-' + item_confirm.history_type_color">{{ item_confirm.fee | makeComma }}원</td>
					<td>{{ item_confirm.wDate }}</td>
					<td>{{ item_confirm.sDate }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'SettlementDepositDetail'
		, props: ['user', 'item', 'virtual_uid']
		, data: function(){
			return {
				program: {

				}
				, items: []
				, item_search: {
					page: 1
					, list_cnt: 10
					, tCnt: 0
					, total_count: 0
				}
				, items_bank: []
			}
		}
		, computed: {
			items_list: function(){
				return this.items.filter( (item, index) => {
					item.no = this.item_search.tCnt - (this.item_search.page * this.item_search.list_cnt - this.item_search.list_cnt) - index

					switch (item.history_type){
						case '0':
							item.history_type_name = '출금'
							item.history_type_color = 'red'
							item.amount *= -1
							break;
						case '1':
							item.history_type_name = '입금'
							item.history_type_color = 'blue'
							break;
					}
					this.items_bank.filter( (bank) => {
						if(bank.code == item.bank_code){
							item.bank_name = bank.name
						}
					})
					return item
				})
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'get'
						,url: 'management/getUserWalletSettlementDetail'
						,data: {
							uid: this.item.uid
							, virtual_uid: this.virtual_uid
						}
					})

					if(result.success){
						this.items = result.data.result
						this.$set(this.item_search, 'total_count', result.data.tCnt)
						this.item_search.tCnt = result.data.tCnt
						this.items_bank = result.data.bank_list
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message })
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.getData()
		}
	}
</script>